import { CTAColorVariant } from '~/components/shared/CTAButton';
import { BoxedPromotionBannerColor, BoxedPromotionBannerVariant } from '.';

export const DEFAULT_PROPS = {
  variant: 'image' as BoxedPromotionBannerVariant,
  bannerColor: 'none' as BoxedPromotionBannerColor,
  blueBlobSubheadline: 'Mark your calendars!',
  blueBlobBody: 'Become a paid member to get 3-hour Early Access starting:',
  month: 'Nov',
  date: '8',
  eyebrow: '',
  headline: 'Early Access to big deals is coming',
  subheadline:
    'Take advantage of 50% off a membership to shop the hottest holiday deals 3 hours early._1_',
  disclaimer: '_1_Paid members only. While supplies last.',
  disclaimerLink: '',
  CTAButtonText: 'Join Walmart+ today',
  CTAButtonVariant: 'primary-yellow' as CTAColorVariant,
  CTAButtonLink: 'https://www.walmart.com/plus/plans',
  hasSecondaryCTAButton: true,
  secondaryCTAButtonText: 'Or try free for 30 days',
  secondaryCTAButtonVariant: 'text-link' as CTAColorVariant,
  secondaryCTAButtonLink: 'https://www.walmart.com/plus/plans',
  images: {
    dealLockup: {
      mobile: 'black-friday-m.png',
      tablet: 'black-friday-m.png',
      desktop: 'black-friday-d.png',
      alt: 'black friday deals logo',
    },
    event: {
      mobile: '50-off-tag.png',
      tablet: '50-off-tag.png',
      desktop: '50-off-tag.png',
      alt: '50% off tag',
    },
    secondEvent: {
      mobile: '',
      tablet: '',
      desktop: '',
      alt: '',
    }
  }
};