/* eslint-disable react/no-unused-prop-types */
import { FunctionComponent } from 'react';
import CTAButton, { CTAColorVariant } from '~/components/shared/CTAButton';
import styles from './BoxedPromotionBanner.module.scss';
import ResponsiveImage from '~/helper/ResponsiveImage';
import {
  handleLinks,
  handleSuperscripts,
  parseTextWithSVG,
} from '~/helper/editableHelpers';
import Tooltip from '~/components/shared/Tooltip';
import MotherStamp from '~/components/shared/svg/MotherStamp';
import MomHackStamp from '~/components/shared/svg/MomHackStamp';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';
import { DEFAULT_PROPS } from './data';

export type BoxedPromotionBannerVariant =
  | 'image'
  | 'date'
  | 'modular-image-center'
  | 'modular-image-top'
  | 'gradient'
  | 'modular-double-image'
  | 'modular-image-center-left';
export type BoxedPromotionBannerColor =
  | 'none'
  | 'blue'
  | 'yellow'
  | 'light-blue';
interface BoxedPromotionBannerProps {
  variant: BoxedPromotionBannerVariant;
  bannerColor: BoxedPromotionBannerColor;
  blueBlobSubheadline?: string;
  blueBlobBody: string;
  month: string;
  date: string;
  headline?: string;
  eyebrow?: string;
  subheadline?: string;
  disclaimer: string;
  disclaimerLink?: string;
  CTAButtonText: string;
  CTAButtonVariant: CTAColorVariant;
  CTAButtonLink: string;
  hasSecondaryCTAButton: boolean;
  secondaryCTAButtonText: string;
  secondaryCTAButtonVariant: CTAColorVariant;
  secondaryCTAButtonLink: string;
  images: {
    dealLockup: {
      mobile: string;
      tablet: string;
      desktop: string;
      alt: string;
    };
    event: {
      mobile: string;
      tablet: string;
      desktop: string;
      alt: string;
    };
    secondEvent?: {
      mobile: string;
      tablet: string;
      desktop: string;
      alt: string;
    };
  };
}

const BoxedPromotionBanner: FunctionComponent<BoxedPromotionBannerProps> = (
  props
) => {
  const propsData = Object.keys(props).length > 0 ? props : null;
  const {
    variant,
    bannerColor,
    blueBlobSubheadline,
    blueBlobBody,
    month,
    date,
    eyebrow,
    headline,
    subheadline,
    disclaimer,
    disclaimerLink,
    CTAButtonText,
    CTAButtonVariant,
    CTAButtonLink,
    hasSecondaryCTAButton,
    secondaryCTAButtonText,
    secondaryCTAButtonVariant,
    secondaryCTAButtonLink,
    images,
  } = propsData ?? DEFAULT_PROPS;

  const baseUrl = process.env.baseUrl;
  const imgPath = `${baseUrl}/images/sections/hero/`;
  const svgs = {
    mother: <MotherStamp className={styles.motherSVG} />,
    momhack: <MomHackStamp className={styles.momhackSVG} />,
  };
  const headlineWithSVG = parseTextWithSVG(
    headline ?? '',
    svgs,
    styles.superscriptXL
  );
  const eyebrowWithSuperscript = handleSuperscripts(
    eyebrow ?? '',
    styles.superscriptSM
  );
  const subheadlineWithSuperscript = handleSuperscripts(
    subheadline ?? '',
    styles.superscriptSM
  );
  const disclaimerWithSuperscript = handleSuperscripts(
    disclaimer,
    styles.disclaimerSuperscript
  );
  const hasDate = variant === 'date';
  const hasGlitterImg = variant === 'image' || variant === 'date';
  const hasSVG = headline?.includes('_SVG_');
  let variantWrapperStyle = styles.imageWrapper;
  let bannerColorStyle = '';
  let textColorStyle = styles.whiteText;

  switch (variant) {
    case 'date':
      variantWrapperStyle = styles.dateWrapper;
      break;
    case 'modular-image-center':
      variantWrapperStyle = styles.modularImageCenterWrapper;
      break;
    case 'modular-image-top':
      variantWrapperStyle = styles.modularImageTopWrapper;
      break;
    case 'gradient':
      variantWrapperStyle = styles.gradientWrapper;
      break;
    case 'modular-double-image':
      variantWrapperStyle = styles.modularDoubleImageWrapper;
      break;
    case 'modular-image-center-left':
      variantWrapperStyle = styles.modularImageCenteLeftWrapper;
      break;
  }

  switch (bannerColor) {
    case 'yellow':
      bannerColorStyle = styles.yellowBackground;
      textColorStyle = styles.blackText;
      break;
    case 'blue':
      bannerColorStyle = styles.blueBackground;
      break;
    case 'light-blue':
      bannerColorStyle = styles.lightBlueBackground;
      textColorStyle = styles.navyBlueText;
  }

  return (
    <div className={variantWrapperStyle}>
      <div className={styles.blobWrapper}>
        <div
          className={
            !!blueBlobSubheadline
              ? styles.blueBlobSubheadlineImg
              : styles.blueBlob
          }
        >
          <ResponsiveImage
            imgPath={imgPath}
            img={{
              mobile: hasGlitterImg
                ? 'blue-glitter-m.jpg'
                : images.event.mobile,
              tablet: hasGlitterImg
                ? 'blue-glitter-m.jpg'
                : images.event.tablet,
              desktop: hasGlitterImg
                ? 'blue-glitter-m.jpg'
                : images.event.desktop,
            }}
            className={styles.glitterImg}
            useNextImg
            fill
            loading='lazy'
            alt={
              hasGlitterImg ? 'blue glitter background image' : images.event.alt
            }
          />
        </div>
        {!hasDate && (
          <div
            className={
              !!blueBlobSubheadline
                ? styles.blueBlobImgWrapperSubheadline
                : styles.blueBlobImgWrapper
            }
          >
            <ResponsiveImage
              className={styles.lockupImg}
              imgPath={imgPath}
              img={{
                mobile: images.dealLockup.mobile,
                tablet: images.dealLockup.tablet,
                desktop: images.dealLockup.desktop,
              }}
              useNextImg
              width={211}
              height={32}
              alt={images.dealLockup.alt}
              loading='lazy'
            />
            {!!blueBlobSubheadline && (
              <h4 className={styles.blueBlobSubheadline}>
                {blueBlobSubheadline}
              </h4>
            )}
          </div>
        )}
        {hasDate && (
          <div className={styles.blueBlobDateContent}>
            <div className={styles.textWrapper}>
              {!!blueBlobSubheadline && (
                <h4 className={styles.blueBlobSubheadline}>
                  {blueBlobSubheadline}
                </h4>
              )}
              {!!blueBlobBody && (
                <h6 className={styles.blueBlobBody}>{blueBlobBody}</h6>
              )}
            </div>
            <div className={styles.numberWrapper}>
              {!!month && <h4 className={styles.month}>{month}</h4>}
              {!!date && <h4 className={styles.date}>{date}</h4>}
            </div>
          </div>
        )}
        <div className={styles.glitterBlob}>
          {hasDate ? (
            <ResponsiveImage
              imgPath={imgPath}
              img={{
                mobile: 'yellow-glitter-m.jpg',
                tablet: 'yellow-glitter-m.jpg',
                desktop: 'yellow-glitter-m.jpg',
              }}
              useNextImg
              fill
              alt='yellow glitter background image'
              loading='lazy'
            />
          ) : (
            <ResponsiveImage
              imgPath={imgPath}
              img={{
                mobile: 'white-glitter.jpg',
                tablet: 'white-glitter.jpg',
                desktop: 'white-glitter.jpg',
              }}
              useNextImg
              fill
              alt='white glitter background image'
              loading='lazy'
            />
          )}
          <ResponsiveImage
            imgPath={imgPath}
            img={{
              mobile: images.event.mobile,
              tablet: images.event.tablet,
              desktop: images.event.desktop,
            }}
            useNextImg
            fill
            alt={images.event.alt}
            loading='lazy'
          />
        </div>
      </div>
      <div className={`${styles.whitePeelWrapper} ${bannerColorStyle}`}>
        <ResponsiveImage
          className={styles.whitePeel}
          imgPath={imgPath}
          img={{
            mobile: 'white-glitter-peel-m.jpg',
            tablet: 'white-glitter-peel-m.jpg',
            desktop: 'white-glitter-peel-d.jpg',
          }}
          useNextImg
          fill
          alt='white glitter background with a page peel in the top right corner'
          loading='lazy'
        />
        <div className={`${styles.content} ${textColorStyle}`}>
          <div className={styles.desktopImageWrapper}>
            <div className={styles.textWrapper}>
              {!!eyebrow && (
                <p className={hasSVG ? styles.eyebrowSVG : styles.eyebrow}>
                  {eyebrowWithSuperscript}
                </p>
              )}
              <h3 className={hasSVG ? styles.headlineSVG : styles.headline}>
                {headlineWithSVG}
              </h3>
              {!!subheadline && (
                <h5 className={styles.subheadline}>
                  {subheadlineWithSuperscript}
                  <Tooltip
                    disclaimer={{
                      id: 'tooltip-boxed-promo-banner',
                      text: !!disclaimerLink
                        ? handleLinks(
                            disclaimer,
                            /(_LINK_[^*]*_LINK_)/,
                            disclaimerLink,
                            styles.disclaimerLink,
                            0,
                            styles.disclaimerSuperscript
                          )
                        : disclaimerWithSuperscript,
                      beakStyle: styles.beak,
                      tooltipWrapperStyle: styles.tooltipWrapper,
                      padding: 0,
                    }}
                    elementPosition='BOXED_PROMO_BANNER'
                  />
                </h5>
              )}
            </div>
            <div className={styles.tagImgWrapper}>
              <ResponsiveImage
                className={styles.tagImg}
                imgPath={imgPath}
                img={{
                  mobile: images.event.mobile,
                  tablet: images.event.tablet,
                  desktop: images.event.desktop,
                }}
                useNextImg
                width={223}
                height={146}
                alt={images.event.alt}
                loading='lazy'
              />
            </div>
          </div>
          <div className={styles.ctaWrapper}>
            <div className={styles.cta1}>
              <CTAButton
                elementPosition='BOXED_PROMO_BANNER'
                elementName='JOIN_CTA'
                variant={CTAButtonVariant}
                elementType='SIGN_UP_CTA_PRIMARY'
                className={styles.ctaButton}
                href={CTAButtonLink}
              >
                {CTAButtonText}
              </CTAButton>
            </div>
            <div className={styles.cta2}>
              {hasSecondaryCTAButton && (
                <CTAButton
                  elementPosition='BOXED_PROMO_BANNER'
                  elementName='OR_START_TRIAL_CTA'
                  variant={secondaryCTAButtonVariant}
                  elementType='SIGN_UP_CTA_SECONDARY'
                  className={styles.ctaButton}
                  href={secondaryCTAButtonLink}
                >
                  {secondaryCTAButtonText}
                </CTAButton>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!images?.secondEvent && (
        <div className={styles.doubleImageWrapper}>
          <div className={styles.doubleImage}>
            <ResponsiveImage
              imgPath={imgPath}
              img={{
                mobile: images.secondEvent.mobile,
                tablet: images.secondEvent.tablet,
                desktop: images.secondEvent.desktop,
              }}
              useNextImg
              fill
              loading='lazy'
              alt={images.secondEvent.alt}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxedPromotionBanner;
